import React, { useEffect, useState, useCallback } from 'react';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import VideoJS from '../components/VideoPlayer';
import RelateVideo from '../components/RelateVideo';
import { TagAds } from '../components/AdsComponent';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import axios from 'axios';
import { BannerAds, GridAds } from '../components/AdsComponent';

const DetailVideo = ({ data }) => {
  const { id, title, litpic, playurl, typename, tag, body, series } = data;
  const [fullplayurl, setFullPlayUrl] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const playerRef = React.useRef(null);
  const [fileId, setFileId] = useState('');


  const fetchUrlsAndTest = useCallback(async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      const response = await axios.get('/c.json');
      const urlPrefixes = response.data.playurl;

      for (const prefix of urlPrefixes) {
        const testUrl = prefix + playurl;
        try {
          await axios.get(testUrl);
          setFullPlayUrl(testUrl);
          const slashIndex = playurl.indexOf('/');
          const fileId = playurl.substring(0, slashIndex);
          setFileId("https://5gmtf.xyz/mp4/"+fileId+".mp4");
          setLoading(false);
          return;
        } catch (e) {
          console.error(`Error Play URL ${testUrl}`);
        }
      }

      setError(true);
    } catch (e) {
      console.error('Error fetching Play URL prefixes:', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [playurl]);

  useEffect(() => {
    fetchUrlsAndTest();
  }, [fetchUrlsAndTest]);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    poster: litpic,
    fluid: true,
    sources: [{
      src: fullplayurl,
      type: 'application/x-mpegURL'
    }]
  };

  const handlePlayerReady = (player) => {
    console.log("zzzzzzz123123")
    playerRef.current = player;

    // 设置播放器属性（确保播放器已经初始化）
    // if (player) {
    //   player.autoplay(false); // 如果播放器已经初始化，再设置 autoplay
    // }

    player.on('error', () => {
      const error = player.error();
      console.error('player error:', error.message);
    });
  };

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="视频" onClick={fetchUrlsAndTest} />;

  return (
    <>
      <Meta title={`${title || '精彩视频'}-5G影院`} description={`在线观看视频-${title || '精彩视频'}`} keywords={`5G影院,${title || '精彩视频'},${tag || ''}`} />
      <BannerAds />
      <GridAds />

      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">5G首页</Link> &gt; <Link to={`/${typename}/`}>{typename}</Link>
      </div>

      {playurl ? (
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      ) : (
      <div className="max-w-[640px] mx-auto">抱歉，视频已下架</div>
      )}

      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">{title}</div>

      {body && <div className="w-full">{body}</div>}

      {series && (
        <>
          <div className="px-2 my-4">所属专辑：</div>
          <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
            <Link to={`/series/${series}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{series}</Link>
          </div>
        </>
      )}

      {tag && (
        <>
          <div className="px-2 my-4">相关标签：</div>
          <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
            {tag.map((t, index) => (
              <Link key={index} to={`/tags/${t}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{t}</Link>
            ))}
            <TagAds />
          </div>
        </>
      )}

      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
        <a href={fileId} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">下载本片</a>
        <a href="https://3u36uh.lol" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">地址找回</a>
      </div>

      <RelateVideo videoId={id} />
    </>
  );
};

export default DetailVideo;
