import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

/**
 * 获取图片类型
 */
const getImageTypeFromUrl = (url) => {
  if (url.includes("/jpg/")) return "jpg";
  if (url.includes("/png/")) return "png";
  if (url.includes("/gif/")) return "gif";
  if (url.includes("/webp/")) return "webp";
  return null;
};

/**
 * 解析并修改图片 URL
 */
const transformImageUrl = (url) => {
 // 匹配文件扩展名（jpg, png, gif, webp）
 const fileExtensionMatch = url.match(/\.(jpg|png|gif|webp)$/i);
 const fileExtension = fileExtensionMatch ? fileExtensionMatch[1].toLowerCase() : null;

 if (!fileExtension) {
     console.warn(`无法解析图片类型，URL 可能无效: ${url}`);
     return url; // 解析失败，返回原 URL
 }

 // 去除文件扩展名（移除 .webp/.jpg/.png/.gif）
 let cleanUrl = url.replace(/\.[a-z]+$/i, "");

 // 确保前缀存在并正确转换
 cleanUrl = cleanUrl.replace(/https:\/\/[^/]+:1443\/pic\//, `https://fazjxevg.xyz:16384/${fileExtension}/`);
 return cleanUrl;
};

const encodeBase64 = (str) => {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);
  let binaryString = "";
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
};
/**
 * 请求并处理 Base64 图片
 */
const fetchAndProcessImage = async (url, setSrc, setError, setLoaded, retryCount) => {
  const transformedUrl = transformImageUrl(url); // 先修改 URL

  // 正则匹配图片类型
  const imageFormatMatch = transformedUrl.match(/\/(jpg|png|gif|webp)\//i);
  const imageType = imageFormatMatch ? imageFormatMatch[1].toLowerCase() : null;

  if (!imageType) {
      console.warn(`URL ${url} does not contain a recognized image format.`);
      return;
  }

  // ✅ 现在 imageFormat 变量中就是 "jpg"、"png"、"gif" 或 "webp"
  console.log(`图片格式: ${imageType}`);
  try {
    console.log(`Fetching ${imageType.toUpperCase()} Image: ${transformedUrl}`);
    const response = await fetch(transformedUrl);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const data = await response.text(); // 获取 Base64 编码数据
    let base64Data = "";
   
    switch (imageType) {
      case "jpg":
        console.log(`Processing JPG Image`);
        base64Data = encodeBase64("\xFF\xD8\xFF" + atob(data) + "\xFF\xD9");
        setSrc(`data:image/jpeg;base64,${base64Data}`);
        break;

      case "gif":
        console.log(`Processing GIF Image`);
        base64Data = encodeBase64("\x47\x49\x46\x38" + atob(data) + "\x00\x3B");
        setSrc(`data:image/gif;base64,${base64Data}`);
        break;

      case "webp":
        console.log(`Processing WEBP Image1111`);
        try {
          // const rawBinary = Uint8Array.from(atob(data), c => c.charCodeAt(0));
          // const header = new TextEncoder().encode("RIFF");
          // const vp8 = new TextEncoder().encode("WEBPVP");
    
          // const processedBinary = new Uint8Array(header.length + rawBinary.length + vp8.length);
          // processedBinary.set(header, 0);
          // processedBinary.set(rawBinary.slice(0, 4), header.length);
          // processedBinary.set(vp8, header.length + 4);
          // processedBinary.set(rawBinary.slice(4), header.length + 4 + vp8.length);
    
          // const processedData = btoa(String.fromCharCode(...processedBinary));
          const data_raw = atob(data); // Base64 解码
          const data_bin = new Uint8Array(data_raw.length);
          for (let i = 0; i < data_raw.length; i++) {
              data_bin[i] = data_raw.charCodeAt(i);
          }

          // 修复 WebP 头部
          const processedData = btoa(String.fromCharCode(...new Uint8Array([
              ...new TextEncoder().encode("RIFF"),
              ...data_bin.slice(0, 4),
              ...new TextEncoder().encode("WEBPVP"),
              ...data_bin.slice(4),
          ])));

          setSrc(`data:image/webp;base64,${processedData}`);
          console.log(`WebP Processed Successfully!`);
        } catch (err) {
          console.error("Failed to process WEBP image", err);
          setError(true);
        }
        break;

      case "png":
        console.log(`Processing PNG Image`);
        break;

      default:
        throw new Error(`Unsupported image type: ${imageType}`);
    }

    setLoaded(true);
  } catch (error) {
    console.error(`Failed to load image ${url}, retry ${retryCount}/3:`, error);
    if (retryCount < 3) {
      setTimeout(() => fetchAndProcessImage(url, setSrc, setError, setLoaded, retryCount + 1), 2000);
    } else {
      setError(true);
    }
  }
};

/**
 * LazyImage 组件
 */
export const LazyImage = ({
  src,
  alt,
  placeholder,
  errorPlaceholder,
  width,
  height,
  className = "",
  delay = 500 // 默认延迟 500ms 加载
}) => {
  const [imageSrc, setImageSrc] = useState(placeholder);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.1 });
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (inView && !loaded && !error) {
      timeoutRef.current = setTimeout(() => {
        fetchAndProcessImage(src, setImageSrc, setError, setLoaded, 0);
      }, delay);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [inView, loaded, error, src, delay]);

  return (
    <div ref={ref} className="lazy-image-wrapper mx-auto">
      <img
        width={width}
        height={height}
        src={imageSrc}
        alt={alt}
        className={`lazy-image w-full h-auto object-cover ${className}`}
        onError={() => {
          setError(true);
          setImageSrc(errorPlaceholder);
        }}
      />
    </div>
  );
};

// 生成 SVG 占位符（灰色背景 + loading 效果）
const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <rect width="${w}" height="${h}" fill="#ddd"/>
  <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#aaa" font-size="16px">图片加载中...</text>
</svg>`;

// 转换成 Base64
const toBase64 = (str) => {
  if (typeof window === "undefined") {
    return Buffer.from(str, "utf-8").toString("base64"); // 服务器端 (Node.js)
  } else {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
        String.fromCharCode("0x" + p1)
      )
    ); // 浏览器端
  }
};
/**
 * 生成占位符
 */
export const placeholder = (w, h) =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`;

/**
 * 生成错误占位符
 */
export const errorPlaceholder = (w, h, message = "加载失败") =>
  `data:image/svg+xml;base64,${toBase64(
    `<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg">
       <rect width="${w}" height="${h}" fill="#f00"/>
       <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#fff" font-size="16px">${message}</text>
     </svg>`
  )}`;
