import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NativeAds, BannerAds, GridAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const Home = () => {
  const [data, setData] = useState([]);
  const [rmData, setRmData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // 公共解密函数
  const decryptData = (cipherTextBase64, ivBase64) => {
    try {
      const cipherText = CryptoJS.enc.Base64.parse(cipherTextBase64);
      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv });
      return decrypted.toString(CryptoJS.enc.Utf8).replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
    } catch (e) {
      console.error("解密失败", e);
      return "";
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(false);

    try {
      // 获取推荐列表数据
      const responseRdlist = await axios.get('/rdlist');
      if (responseRdlist.data.status === 200) {
        const updatedRmData = responseRdlist.data.data.map((item) => {
          if (item.title && typeof item.title === "object") {
            const plainText = decryptData(item.title.k1, item.title.v2);
            return { ...item, title: { ...item.title, k1: plainText } };
          }
          return item;
        });
        setRmData(updatedRmData);
      }

      // 获取首页数据
      const responseHome = await axios.get('/home');
      if (responseHome.data.status === 200) {
        const rawData = responseHome.data.data.map((item) => {
          const plainText = decryptData(item.k1, item.v2);
          return { ...item, ...JSON.parse(plainText) };
        });

        // 按 `typename` 分组
        const groupedData = rawData.reduce((acc, item) => {
          acc[item.typename] = acc[item.typename] || [];
          acc[item.typename].push(item);
          return acc;
        }, {});
        setData(groupedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="首页更新" onClick={fetchData} />;

  // 插入广告到数据中
  const insertAdIntoData = (items) => {
    if (!items.length) return items;
    const randomIndex = Math.floor(Math.random() * (items.length + 1));
    return [
      ...items.slice(0, randomIndex),
      { title: "itemAd" },
      ...items.slice(randomIndex),
    ];
  };

  // 处理推荐数据
  const dataWithAd = insertAdIntoData(rmData);

  return (
    <>
      <Meta title="首页-5G影院" description="欢迎来到5G影院，这里有最精彩的在线视频，每天更新永久免费" keywords="5G影院, 首页, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds />
      
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4 flex justify-between items-center" style={{ marginTop: "10px" }} onClick={() => fetchData()}>
        精选视频 <span style={{ marginLeft: "20px" }}>换一批视频</span>
      </div>
      
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {dataWithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === "itemAd" ? (
              <NativeAds />
            ) : (
              <Link to={`/${item.id}/`} className="block">
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title.k1}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title.k1)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: "3px" }}>
                  {item.title.k1}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      
      {Object.entries(data).map(([category, items]) => {
        const itemsWithAd = insertAdIntoData(items);
        return (
          <div key={category}>
            <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
              <Link to={`/h/${category}/`}>{category}</Link> 最新上架
            </div>
            <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
              {itemsWithAd.map((item, index) => (
                <div key={item.id || `ad-${category}-${index}`} className="break-inside-avoid mb-4">
                  {item.title === "itemAd" ? (
                    <NativeAds />
                  ) : (
                    <Link to={`/${item.id}/`} className="block">
                      <LazyImage
                        className="w-full h-auto object-cover"
                        src={item.litpic}
                        alt={item.title}
                        placeholder={placeholder(300, 200)}
                        errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                        width={300}
                        height={200}
                      />
                      <p className="text-center mt-2 text-sm">{item.title}</p>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Home;
